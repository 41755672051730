<template>
  <main class="color-primary h-100 page_bg_grey blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">
    <p class="main-title weight-700">
      {{ $t('Tag management') }}
    </p>
    <div class="sm-content">
      <div class="d-flex">
        <p class="weight-700 mx-10 mt-12">{{ $t('Filter name') }}</p>
        <div class="tag-input">
          <label>{{ $t('Tag type') }}</label>
          <vue-select
            :options="tagTypeList"
            class="input cus-select"
            v-model="tagType"
          ></vue-select>
        </div>
        <div class="tag-input mx-10">
          <label>{{ $t('Tag name') }}</label>
          <input type="text" class="input">
        </div>
        <button class="button filter__button" @click="filter()"></button>
      </div>
      <v-data-table
        :headers="headers"
        :items="tags"
        class="cus-table padding-table main-table mt-10"
        item-key="id"
      >
        <template v-slot:body="{ items }">
          <tbody v-if="loading==false && tags.length==0">
            <tr>
              <td colspan="7" class="text-center"> 
                There is no data.
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr 
              v-for="item in items"
              :key="item.id"
            >
              <td>  
                <div class="d-flex justify-content-center align-items-center h-100">
                  {{ $t(getTagTypeName(item.type)) }}
                </div>
              </td>
              <td>  
                <div class="d-flex justify-content-center align-items-center h-100">
                  {{ item.tag_name }}
                </div>
              </td>
              <td>  
                <div class="d-flex justify-content-center align-items-center h-100">
                  <v-checkbox 
                    color="#0D3856" 
                    class="cus-checkbox" 
                    style="padding: 0;"
                    v-model="item.show_on_product"
                  ></v-checkbox>
                </div>
              </td>
              <td>
                <div class="d-flex justify-content-center align-items-center h-100">
                  {{ item.order }}
                </div>  
              </td>
              <td>  
                <div class="d-flex justify-content-center align-items-center h-100">
                  <v-checkbox 
                    color="#0D3856" 
                    class="cus-checkbox" 
                    style="padding: 0;"
                    :v-model="true"
                  ></v-checkbox>
                </div>
              </td>
              <td>  
                <div class="d-flex justify-content-center align-items-center h-100">
                   <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }"> 
                      <img src="/assets/img/icons/edit.svg" 
                        alt="" 
                        v-bind="attrs" 
                        v-on="on" 
                        class="edit-icon pointer"
                        @click="openModalEditTag(item.id)" 
                      />
                    </template>
                    <span>{{ $t("Edit") }}</span>
                  </v-tooltip>
                </div>
              </td>
              <td>
                <div class="d-flex justify-content-center align-items-center h-100">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div class="red-close_btn pointer" v-bind="attrs" v-on="on" @click="deleteOneTag(item.id)">
                        <img src="/assets/img/icons/cross-red.svg" alt=""/>
                      </div>
                    </template>
                    <span>{{ $t("Delete") }}</span>
                  </v-tooltip>
                </div>  
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
      <div class="d-flex mt-7">
        <!-- <router-link to="/management/sc/tag-management/edit-category" class="primary-btn primary-btn_sm bg-yellow color-white">
          {{ $t("Create a new type") }}
        </router-link> -->
        <v-btn
          color="teal"
          class="text-none mx-5"
          dark
          @click="openModalEditTag(null)" 
        >
          {{ $t("Create a new tag") }}
        </v-btn>
      </div>
    </div>
    <ModalEditTag ref="modalEditTagRef" @submitted="handleEditCallback"></ModalEditTag>
    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
  </main>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Swal from 'sweetalert2';
import { TagTypeConst } from "@/common/constants.js";
import ModalEditTag from "./ModalEditTag.vue";

export default {
  name: 'SCTagmanagement',
  components: {
    ModalEditTag
  },
  data() {
    return {
      headers: [
        {
          text: this.$t('Tag type'),
          align: 'center',
          sortable: false,
          value: 'type',
        },
        {
          text: this.$t('Tag name'),
          align: 'center',
          sortable: false,
          value: 'tag_name',
        },
        {
          text: this.$t('Appears on the product'),
          align: 'center',
          sortable: false,
          value: 'show_on_product',
        },
        {
          text: this.$t('Order'),
          align: 'center',
          sortable: false,
          value: 'order',
        },
        {
          text: this.$t('Active'),
          align: 'center',
          sortable: false,
          value: 'active',
        },
        {
          text: this.$t('Editing'),
          align: 'center',
          sortable: false,
          value: 'editing',
        },
        {
          text: this.$t('Deletion'),
          align: 'center',
          sortable: false,
          value: 'deletion',
        }
      ],
      tagType: {},
      tagTypeList: TagTypeConst
    }
  },
  computed: {
    ...mapState({
      loading: (state) => state.tag_management.loading,
      tags: state => state.tag_management.tags,
    }),
  },
  created () {
    // if (this.tags.length == 0) {
      this.getTags();
    // }
  },
  methods: {
    ...mapActions('tag_management', {
      getTags: 'getTags',
      deleteTag: 'deleteTag',
      filterTag: 'filterTag'
    }),
    deleteOneTag(id) {
      Swal.fire({
        title: this.$t("Are you sure?"),
        text: this.$t("Do you really want to delete this?"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t("Delete"),
        cancelButtonText: this.$t("Cancel"),
        preConfirm: () => {
          this.deleteTag({
            id: id
          });
        }
      })
    },
    filter() {
      this.filterTag({type: this.tagType.code})
    },
    getTagTypeName(id) {
      const type = this.tagTypeList.find(el => {
        return el.code == id;
      });
      return type.label;
    },
    openModalEditTag(id) {
      let isEdit = true;
      if (id) {
        const tagData = this.tags.filter((tag) => {
          return id == tag.id;
        })[0];
        const tagType = this.tagTypeList.find(el => {
          return el.code == tagData.type;
        });
        this.$refs.modalEditTagRef.openModal({isEdit, tagData, tagType});
      } else {
        isEdit = false;
        this.$refs.modalEditTagRef.openModal({isEdit});
      }
    },
    handleEditCallback() {
      this.getTags();
    }
  }
}
</script>

<style lang="scss" scoped>
.tag-input {
  max-width: 200px;
}
.filter__button {
  margin-top: 27px;
}
.filter-name {
  margin-top: 32px;
  margin-left: 20px;
}
.padding-table.v-data-table tbody tr td {
  padding: 3px 0;
}
.cus-select {
  min-width: 200px;
}
</style>