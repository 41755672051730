<template>
  <modal
    :width="570"
    :adaptive="true"
    class="CEModal modal-scroll-bar"
    name="ModalEditTag"
  >
    <button class="button cus-close-modal_btn" @click="closeModal()">
      <img src="/assets/img/close.svg" alt />
    </button>
    <div class="font-weight-bold fs18 grey lighten-3 px-12 py-9">
			{{ $t("Edit Tag") }}
		</div>

    <div class="py-10 px-20 fs14">
      <div class="mb-10">
        <label for="tag_type">
          {{ $t("Tag type") }}
        </label>
        <vue-select
          id="tag_type"
          :options="tagTypeList"
          class="input cus-select"
          v-model="tagType"
        ></vue-select>
      </div>
      <div class="mb-10 validation-form-group" :class="{
        'form-group--error': $v.tagData.tag_name.$error,
      }">
        <label for="tag_name">
          {{ $t("Tag Name") }} <span class="red--text">*</span>
        </label>
        <input type="text" class="input" id="tag_name" v-model="tagData.tag_name">
        <div class="color-red1 fs12 valide-error-msg" v-if="$v.tagData.tag_name.$error">
          {{ $t('Invalid value') }}
        </div>
      </div>
      <div class="mb-10">
        <label for="order">
          {{ $t("Order") }}
        </label>
        <input type="text" class="input" id="order" v-model="tagData.order">
      </div>

      <div class="d-flex align-items-center">
        <v-checkbox 
          color="#0D3856" 
          class="cus-checkbox mb-2"
          id="appears_on_product"
          v-model="tagData.show_on_product" 
        ></v-checkbox>
        <label for="appears_on_product" class="mx-5">{{ $t('Appears on the product') }}</label>
      </div>
      <div class="d-flex justify-content-end">
        <v-btn
          class="text-none mx-10"
          @click="closeModal()"
        >
          {{ $t("Cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          class="text-none"
          @click="submit"
        >
          {{ $t("Save") }}
        </v-btn>
      </div>
    </div>
  </modal>	
</template>

<script>
import { mapActions } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { TagTypeConst } from "@/common/constants.js";

export default {
  name: "ModalEditTag",
  mixins: [validationMixin],
  data() {
    return {
      isEdit: true,
      tagData: {},
      tagTypeList: TagTypeConst,
      tagType: {}
    }
  },
  validations: {
    tagData: {
      tag_name: {
        required,
      },
    },
  },
  methods: {
    ...mapActions('tag_management', {
      updateTag: 'updateTag',
      createTag: 'createTag',
    }),
    closeModal() {
      this.$modal.hide("ModalEditTag");
    },
    openModal({isEdit, tagData, tagType}) {
      this.isEdit = isEdit;
      if (isEdit) {
        this.tagData = tagData;
        this.tagType = tagType;
        this.$modal.show("ModalEditTag");
      } else {
        this.tagData = {};
        this.tagType = this.tagTypeList[0];
        this.$modal.show("ModalEditTag");
      }
    },
    async submit() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      };

      this.tagData.type = this.tagType.code;
      if (this.isEdit) {
        const id = this.tagData.id;
        // this.tagData.name = this.tagData.tag_name;
        await this.updateTag({id: id, params: this.tagData});
      } else {
        await this.createTag({params: this.tagData});
      }

      this.closeModal();
      this.$emit('submitted');
    }
  }
}
</script>